@import "../../SCSS_Variables.scss";

.workflow-layout {
    .footer-navigation {
        // position: fixed;
        // bottom: 0px;
        // height: 64px;
        width: 100%;
        // background-color: transparent;
        // 225 is the sidnav size and 48 is the content margin * 2
        // width: calc(100% - 225px - 48px);
        // background-color: lightgreen;
        background-color: #ebebeb;
        padding: 16px;
        border-top: rgba(0, 0, 0, 0.45) solid 2px;

        // *.right-align {
        //     position: absolute;
        //     right: 16px;
        // }

        button {
            min-width: 200px;
        }
    }
}
