@import "../../../SCSS_Variables.scss";
.pricing-page {
    margin-top: 30px;
    position: relative;
    align-content: center;
    text-align: center;
    display: block;

    > .ant-row {
        > div {
            padding: 24px;
        }
    }

    .pricing-card{
        margin-bottom: 16px;

        box-sizing: border-box;
        // width  : 100px;
        // height : 100px;
        border    : 10px solid $primary-color;
    }

    .pricing-tier{
        margin-bottom: 5px;
        border    : 2px groove $primary-color;
    }

}
