@import "../../SCSS_Variables.scss";

.benefit-me-page {
    .ant-card {
        margin-bottom: 16px;
        box-sizing   : border-box;
        border       : 10px solid $primary-color;
    }

    .center {
        text-align: center;
    }

    .center-img {
        display     : block;
        margin-left : auto;
        margin-right: auto;
        width: 100%;
    }

    .bold {
        font-weight: bold;
    }

    .underline {
        text-decoration: underline;
    }
}
