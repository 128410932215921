.teacher-home {

    .ant-table-wrapper {
        border: 2px solid lightslategray;
        border-radius: 8px;
        padding: 2px;
    }

    .table-row-light {
        background-color: #ffffff;
    }

    .table-row-dark {
        background-color: #f8f8f8;
    }

    .add-some-space {
        // Adds space because I want it to
        margin-bottom: 12px;

        h2 {
            margin-bottom: 4px;
        }

        a {
            display: inline-block;
            margin-bottom: 12px;
        }
    }
}
