.title{
    color:red;
    font-size: 24px;
}

.content{
    color:red;
    font-size: 20px;
}

.large-space{
    color:red;
    font-size: 20px;
    white-space: pre-line;
  }