@import "../../SCSS_Variables.scss";

.about-us-page {
    .ant-card {
        margin-bottom: 16px;
        box-sizing   : border-box;
        border       : 10px solid $primary-color;
    }

    .center {
        text-align: center;
    }

    .right {
        text-align: right;
    }

    .center-img {
        display     : block;
        margin-left : auto;
        margin-right: auto;
    }

    .small-text {
        font-size: 10px;
    }

    .italics {
        font-style: italic;
    }

    .bold {
        font-weight: bold;
    }

    .lower-space {
        margin-bottom: 50px;
    }

    .ant-card-body {
        p {
            font-size: 18px;
        }
    }

    .reviews-section {
        img {
            height: 300px;
        }
    }
}