.general-footer {
    &.ant-layout-footer {
        padding   : 0px;
        background: #77787d;
    }

    // Make the text and icons white
    a, p {
        color: white;
    }

    .icon-row {
        font-size      : 18px;
        text-align     : center;
        text-decoration: none;
        margin         : 5px 2px;

        .ant-col {
            width: 50px;
        }
    }

    .copyright-row p {
        margin-bottom: 4px;
    }

    .ant-layout-sider-trigger {
        position: absolute !important;
    }

    .img-size {
        width: 28px;
    }

    .text-style {
        color     : white;
        text-align: center;
    }
}