@import "../../SCSS_Variables.scss";
.musics-effectiveness-page {
    .ant-card {
        margin-bottom: 16px;
        box-sizing: border-box;
        border    : 10px solid $primary-color;
    }
    .center{
        text-align: center;
    }
    .bold{
        font-weight: bold; 
    }
}