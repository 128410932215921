@import "../../SCSS_Variables.scss";

// CSS for the workflow pages

.pdf-container,
.react-video-container {
    // %100 - header - footer. Then subtract (2 x padding (16px)), (h1 height) and (p height + 1)
    height: calc(100vh - #{$layout-header-size} - #{$layout-footer-size} - 32px - 58px - 36px);
    width : 100%;

    iframe {
        height: 100%;
        width : 100%;
    }
}

.pdf-container-with-input {
    // Same as the .pdf-container above but with space for an input box
    height: calc(100vh - #{$layout-header-size} - #{$layout-footer-size} - 32px - 58px - 105px);
    width : 100%;

    iframe {
        height: 100%;
        width : 100%;
    }
}

.course-name {
    margin-top: -22px; // I hate this more than you do, don't you worry
}

.demo-helper-text {
    color        : red;
    font-weight  : 500;
    font-size    : 16px;
    margin-top   : -8px;
    margin-bottom: 8px;
}