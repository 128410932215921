.teacher-layout {
    .footer-navigation {
        // position: fixed;
        // bottom: 0px;
        height: 64px;
        width: 100%;
        // 225 is the sidnav size and 48 is the content margin * 2
        // width: calc(100% - 225px - 48px);
        // background-color: lightgreen;
        padding: 16px;

        > div > *.right-align {
            position: absolute;
            right: 16px;
        }

        button {
            min-width: 200px;
        }
    }
}
