@import "./SCSS_Variables.scss";

.App {
    // Making the app a better single page experience
    > .ant-layout {
        height: 100vh;
    }

    // Give the layouts and content area the same color scheme
    .ant-layout, .ant-layout-content {
        background-color: $layout-content-background;
        color: $text-color-secondary;
    }

    // Content area padding
    .ant-layout-content {
        padding: 16px;
        overflow-y: auto;
    }

    // Header
    .general-header {
        background-color: $layout-header-background;
        color: $text-color-secondary;
        padding: 0 24px; // Pad the left and right
        height: initial;
        overflow: hidden;
        // Get all items just under the header
        // > * {
        //     display: inline-block;
        //     max-height: 64px;
        // }

        // li {
        //     line-height: 32px;
        // }

        .header-item{
            margin-top: 10px;
            font-size: 27px;
        }

        .logo {
            // line-height: 48px;
            .header-logo {
                height: 60px;
                // padding-top: 4px;
                // margin: 16px 28px 16px 0;
                // height: 48px;
                // margin: 8px;
                // width: 64px;
            }
        }

        .static-links {
            text-align: center;
            // height: 40px;
            // margin-top: 15px;
            line-height: 64px;
        }

        .header-nav {
            // Last in the order and set to be part of the right side
            // position: absolute;
            // right: 24px;
            // top: 16px;
            width: 110px;
        }

        // Dev Note: These were created because the XL and MD breaks are just off enough to want them
        @media only screen and (max-width: 1216px) {
            .sel-container {
                display: none;
            }
        }

        @media only screen and (max-width: 1044px) {
            .me-container {
                display: none;
            }
        }

        @media only screen and (max-width: 888px) {
            .rdb-container {
                display: none;
            }
        }

        @media only screen and (max-width: 706px) {
            .about-container {
                display: none;
            }
        }

        @media only screen and (max-width: 614px) {
            .register-container {
                display: none;
            }
        }

        @media only screen and (max-width: 530px) {
            .login-container {
                display: none;
            }
        }
    }
}

.ant-layout-header{
    line-height: unset;
}

body{
    font-family: 'Gotham';
}

// I think the antd.css file is messing with me. SOMEHOW, the h1 tag gets all garbled up. This fixes it
h1 {
    font-size: 2rem;
}

h2 {
    font-size: 1.5rem;
}

// Large Spinner
div.ant-spin.very-large-spinner >span {
    height: 14vw;
    width: 14vw;
    > i {
        height: 6vw;
        width: 6vw;
    }
}
.spinner-centered {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

// https://stackoverflow.com/questions/356809/best-way-to-center-a-div-on-a-page-vertically-and-horizontally
div.centered {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
}

// Font Awesome
i.far, i.fas, i.fab,
div.far, div.fas, div.fab {
    font-size: 14px;
    width: 14px;
    text-align: center;
}

// More Icon stuff
.ant-menu-submenu, .ant-menu-item {
    i.anticon {
        font-size: 18px;
    }
}

// Radio buttons
.ant-radio-group {
    &.vertical {
        label.ant-radio-wrapper {
            display: block;
        }
    }
    &.left-padding {
        padding-left: 24px;
    }
}

// TODO: JB - Move this and the other layout things into other files
// Sidenav
.side-nav {
    // overflow: auto;
    // height: calc(100vh - #{$layout-header-size});
    left: 0;
    background-color: $layout-sider-background;
    min-height: 100%;
    padding-bottom: 48px;

    // Adjustments for the main menu AND sub-menus
    ul.ant-menu {
        // Make the menu the same heigh as the slider
        // padding-bottom: 48px;
        background-color: $layout-sider-background;
        border-right-width: 0px;

        // Not ready for prime time
        // > * {
        //     background-color: $layout-sider-background;
        //     color: white;
        //     font-weight: 700;
        // }

        // Color menu items and sub-menu items
        li.ant-menu-item, div.ant-menu-submenu-title > a {
            width: 100%;
            background-color: $layout-sider-background;
            color: white;
            font-weight: 700;

            > a {
                color: white;
            }
        }

        // Override for selected element. Makes the selection 'pop'
        li.ant-menu-item-selected {
            box-shadow: 2px 1px 2px 2px $layout-sider-selected;
        }
    }

    // Sub-menu specific adjustments
    li.ant-menu-submenu {
        color: white;
        font-weight: 700;
        ul {
            padding-bottom: 0px;
        }
    }

    // Styles for changing active school
    .active-school {
        padding: 8px 20px;
        color: white;
        font-size: 14px;
        font-weight: 700;
    }
    &.ant-layout-sider-collapsed .active-school {
        display: inline-block;
        max-width: 0;
        opacity: 0;
    }
}

// Header Menu
// Note: Menues are typically outside of the normal page DOM
.util-menu {
    button {
        width: 100%;
        min-width: 150px;
    }
    .bbutton {
        margin: 0;
        width: 100%;
        min-width: 150px;
    }
}

// Strip credit card inputs
.StripeElement {
    box-sizing: border-box;
    height: 40px;
    padding: 10px 12px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    background-color: white;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
}

.StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
    border-color: #f5222d;
    border: 1px solid #f5222d;
}

.StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
}

.has-error > span > .StripeElement {
    border-color: #f5222d;
}

// Errors on the table and tab elements
.table-row-error {
    color: red;
    font-weight: 700;
}
.table-icon-error {
    color: red;
}

//set overflow of tables for mobile
.ant-table-title + .ant-table-content {
    overflow-x: auto;
}

// Special danger popup
.danger-popup {
    .ant-modal-content {
        background-color: #f86b73;

        .ant-modal-confirm-body .ant-modal-confirm-content,
        .ant-modal-confirm-title {
            color: white;
            font-weight: 700;
            margin-left: 24px;
        }
    }

    // center the icon
    span.anticon {
        float: unset;
        font-size: 45px;
        text-align: center;
        margin: unset;
        display: block;
    }
}

