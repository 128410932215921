@import "../SCSS_Variables.scss";

.home-page {
    text-align: center;

    h1 {
        font-size: 42px;
    }

    .color-me-blue {
        background-color: #0333cc;
        color           : white;
        font-weight     : 700;
        padding         : 14px;
    }

    .ant-card {
        margin-bottom: 16px;

        box-sizing: border-box;
        // width  : 100px;
        // height : 100px;
        border    : 10px solid $primary-color;
    }

    .ant-card-body {
        padding: 32px 16px;
        // This will center the text next to the image
        // display    : flex;
        // align-items: center;

        .homepage-image {
            width     : auto;
            height    : auto;
            max-height: 220px;
            max-width : 100%;
        }

        .merch-image {
            width: 100%;
            padding: 0 6px;
            height: auto;
        }

        p {
            font-size    : 18px;
            padding      : 8px;
            padding-right: 16px;
            margin       : unset; // Helps with centering
        }

        ul {
            font-size : 18px;
            text-align: left;
        }
    }
}